/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import * as VueGoogleMaps from 'gmap-vue';
import ServiceContainer from '@flashpointbv/solar-service-container';
import Environment from '@flashpointbv/solar-ui-environment';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
import {UICoreSticky, UICoreOffCanvas} from '@flashpointbv/solar-ui-core';
import {SolarDataLayer} from '@flashpointbv/solar-datalayer/dist';

SolarDataLayer.init();

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);

Vue.use(VueI18n);
Vue.use(VueLazyload);
Vue.use(Vuex);
Vue.use(VueGoogleMaps, {
    load: {
        key: window.Config['google_api_key'],
        libraries: 'places',
        region: window.Locale.substring(0, 2),
        language: window.Locale.substring(0, 2).toLowerCase()
    }
});

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substr(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {CatalogCategory, CatalogProduct, CatalogBundleProduct, CatalogGroupedProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog/src';
import {CheckoutGlobal, CheckoutCart, CheckoutCheckout, CheckoutShipping, CheckoutPayment, CheckoutTotals} from '@flashpointbv/solar-ui-checkout/dist';
import CatalogCategoryReviews from './stores/catalog/category/reviews';
import CatalogCategoryCompare from './stores/catalog/category/compare';
import CustomerWishlist from '../../../../../vendor/flashpoint/solar-wishlist/resources/resources/assets/js/stores/wishlist';

const store = new Vuex.Store({
    strict: false,
});

store.registerModule('CustomerWishlist', CustomerWishlist);
store.registerModule('CatalogCategory', CatalogCategory);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);

store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutCart', CheckoutCart);
store.registerModule('CheckoutCheckout', CheckoutCheckout);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutTotals', CheckoutTotals);

store.registerModule('CatalogCategoryReviews', CatalogCategoryReviews);
store.registerModule('CatalogCategoryCompare', CatalogCategoryCompare);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
    {key: 'footer-subscribe', concrete: () => import('./components/FooterSubscribe.vue')},
    {key: 'block-slider', concrete: () => import('./components/blocks/BlockSlider.vue')},
    {key: 'home-list', concrete: () => import('./components/HomeList.vue')},
    {key: 'header-cart', concrete: require('./components/HeaderCart').default},
    {key: 'header-wishlist', concrete: require('./components/HeaderWishlist.vue').default},

    //Homepage & Landingpage Builder
    {key: 'home-slider', concrete: () => import('./components/home/HomeSlider.vue')},

    //Autocomplete
    {key: 'search-autocomplete', concrete: require('./components/search/SearchForm').default},
    {key: 'search-autocomplete-list-item', concrete: () => import('./components/search/AutocompleteListItem.vue')},

    //Catalog
    {key: 'catalog-category-view', concrete: () => import('./views/catalog/CatalogCategoryView.vue')},
    {key: 'catalog-search-view', concrete: () => import('./views/catalog/CatalogSearchView.vue')},

    {key: 'catalog-product-count', concrete: () => import('./components/catalog/ProductCount.vue')},
    {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
    {key: 'catalog-product-compare', concrete: () => import('./components/catalog/ProductCompare.vue')},
    {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/ProductListToolbar.vue')},
    {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue')},
    {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
    {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/ProductListToolbarSortSelect.vue')},
    {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('./components/catalog/ProductListToolbarPaginator.vue')},
    {key: 'catalog-up-sell-items-slider', concrete: () => import('./components/catalog/UpSellItemsSlider.vue')},
    {key: 'catalog-related-items-slider', concrete: () => import('./components/catalog/RelatedItemsSlider.vue')},

    {key: 'catalog-product-compare-index', concrete: () => import('./components/catalog/compare/Index.vue')},
    {key: 'catalog-product-compare-product', concrete: () => import('./components/catalog/compare/Product.vue')},

    //Landing page
    {key: 'catalog-landing-usp-slider', concrete: () => import('./components/catalog/landing/UspSlider.vue')},
    {key: 'catalog-landing-filter-slider', concrete: () => import('./components/catalog/landing/FilterSlider.vue')},

    //Catalog Filters
    {key: 'catalog-product-list-attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
    {key: 'catalog-product-list-swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
    {key: 'catalog-product-list-price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},

    //Catalog Product
    {key: 'catalog-product-configuration', concrete: () => import('./components/catalog/product/ProductConfiguration.vue')},
    {key: 'catalog-product-cross-sell-off-canvas', concrete: () => import('./components/catalog/product/ProductCrossSellOffCanvas.vue')},
    {key: 'catalog-product-price', concrete: () => import('./components/catalog/product/ProductPrice.vue')},
    {key: 'catalog-product-media', concrete: () => import('./components/catalog/product/ProductMedia.vue')},
    {key: 'catalog-product-media-mobile', concrete: () => import('./components/catalog/product/ProductMediaMobile.vue')},
    {key: 'catalog-product-simple', concrete: () => import('./components/catalog/product/simple/ProductTypeSimple.vue')},
    {key: 'catalog-product-configurable', concrete: () => import('./components/catalog/product/configurable/ProductTypeConfigurable.vue')},
    {key: 'catalog-product-configurable-select', concrete: () => import('./components/catalog/product/configurable/type/OptionSelect.vue')},
    {key: 'catalog-product-configurable-swatch', concrete: () => import('./components/catalog/product/configurable/type/OptionSwatch.vue')},
    {key: 'catalog-product-bundle', concrete: () => import('./components/catalog/product/bundle/ProductTypeBundle.vue')},
    {key: 'catalog-product-bundle-select', concrete: () => import('./components/catalog/product/bundle/type/BundleSelect.vue')},
    {key: 'catalog-product-bundle-radio', concrete: () => import('./components/catalog/product/bundle/type/BundleRadio.vue')},
    {key: 'catalog-product-bundle-checkbox', concrete: () => import('./components/catalog/product/bundle/type/BundleCheckbox.vue')},
    {key: 'catalog-product-grouped', concrete: () => import('./components/catalog/product/grouped/ProductTypeGrouped.vue')},
    {key: 'catalog-product-grouped-product', concrete: () => import('./components/catalog/product/grouped/ProductTypeGroupedProduct.vue')},
    {key: 'catalog-product-sticky-add-to-cart', concrete: () => import('./components/catalog/product/StickyAddToCart.vue')},
    {key: 'catalog-product-add-to-cart', concrete: () => import('./components/catalog/product/ProductAddToCart.vue')},

    //Cart
    {key: 'checkout-cart', concrete: () => import('./components/checkout/cart/Cart.vue')},
    {key: 'checkout-coupon-form', concrete: () => import('./components/checkout/coupon/CouponForm.vue')},
    {key: 'checkout-cart-items', concrete: () => import('./components/checkout/cart/CartItems.vue')},
    {key: 'checkout-cart-items-list', concrete: () => import('./components/checkout/cart/CartItemsList.vue')},
    {key: 'checkout-cart-totals', concrete: () => import('./components/checkout/cart/CartTotals.vue')},
    {key: 'checkout-cart-related', concrete: () => import('./components/checkout/cart/ProductCrosssellList.vue')},
    //Checkout
    {key: 'checkout-checkout', concrete: () => import('./components/checkout/checkout/Checkout.vue')},
    {key: 'checkout-newsletter', concrete: () => import('./components/checkout/checkout/CheckoutNewsletter.vue')},

    {key: 'checkout-address-detail', concrete: () => import('./components/checkout/checkout/step/AddressDetail.vue')},
    {key: 'checkout-address-shipping', concrete: () => import('./components/checkout/checkout/address/ShippingAddress.vue')},
    {key: 'checkout-address-billing', concrete: () => import('./components/checkout/checkout/address/BillingAddress.vue')},
    {key: 'checkout-address-login', concrete: () => import('./components/checkout/checkout/address/CheckoutLogin.vue')},
    {key: 'checkout-address-login-form', concrete: () => import('./components/checkout/checkout/address/CheckoutLoginForm.vue')},
    {key: 'checkout-address-autocomplete', concrete: () => import('./components/checkout/checkout/address/input/AddressAutocomplete.vue')},
    {key: 'checkout-address-form', concrete: () => import('./components/checkout/checkout/address/input/AddressForm.vue')},
    {key: 'checkout-address-company-input', concrete: () => import('./components/checkout/checkout/address/input/AddressCompanyInput.vue')},
    {key: 'checkout-address-select', concrete: () => import('./components/checkout/checkout/address/input/AddressSelect.vue')},
    {key: 'checkout-address-option', concrete: () => import('./components/checkout/checkout/address/input/AddressOption.vue')},

    {key: 'checkout-shipping-detail', concrete: () => import('./components/checkout/checkout/step/ShippingDetail.vue')},
    {key: 'checkout-shipping-methods-default', concrete: () => import('./components/checkout/checkout/shipping/methods/Default.vue')},

    {key: 'checkout-payment-detail', concrete: () => import('./components/checkout/checkout/step/PaymentDetail.vue')},
    {key: 'checkout-payment-methods-default', concrete: () => import('./components/checkout/checkout/payment/methods/Default.vue')},

    {key: 'checkout-summary', concrete: () => import('./components/checkout/checkout/summary/Summary.vue')},
    {key: 'checkout-totals', concrete: () => import('./components/checkout/checkout/summary/CheckoutTotals.vue')},

    // Customer
    {key: 'customer-address-autocomplete', concrete: () => import('./components/customer/AddressAutocomplete.vue')},
    {key: 'customer-wishlist', concrete: () => import('./components/customer/CustomerWishlist.vue')},

    //Sliders and swipers
    {key: 'swiper', concrete: () => import('./shaked-exports/swiper/swiper')},
    {key: 'swiper-slide', concrete: () => import('./shaked-exports/swiper/swiper-slide')},

    //Rest
    {key: 'store-locator', concrete: () => import('./components/cms/StoreLocator.vue')},

    //Lazy load components
    {key: 'lazy-load', concrete: () => import('./components/LazyLoad.vue')},
]);

import nl_NL from './i18n/nl_NL';
import en_GB from './i18n/en_GB';
import de_DE from './i18n/de_DE';

import {localize} from 'vee-validate';
import vee_en_GB from 'vee-validate/dist/locale/en.json';
import vee_nl_NL from 'vee-validate/dist/locale/nl.json';
import vee_de_DE from 'vee-validate/dist/locale/de.json';

localize({vee_en_GB, vee_nl_NL, vee_de_DE});
localize(`vee_${window.Locale}`);

const messages = {
    nl_NL,
    en_GB,
    de_DE
};

const i18n = new VueI18n({
    locale: window.Locale,
    fallbackLocale: 'nl_NL',
    formatFallbackMessages: true,
    silentTranslationWarn: true,
    messages
});

new Vue({
    store,
    i18n,
    components: ServiceContainer().getComponents(),
    data: {
        csrfToken: window.csrfToken
    },
    async created() {
        await this.$store.dispatch('CheckoutGlobal/initialize');
        await this.$store.commit('CheckoutCart/SET_DEFAULT_CARRIER_DATA', {
            methodCode: window.Config['default_method_code'],
            carrierCode: window.Config['default_carrier_code'],
        });

        this.$eventBus.$on('item-rendered', () => {
            const catalogCategoryProductPlaceholders = document.querySelector('.category__placeholder');
            if (catalogCategoryProductPlaceholders) {
                (catalogCategoryProductPlaceholders as HTMLElement).style.display = 'none';
            }
        });
    }
}).$mount('#solar-app');
